import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Signin from './Signin';

import 'semantic-ui-css/semantic.min.css'
// import { Link } from 'react-router-dom';

import 'react-responsive-modal/styles.css';
import './navbar.css';


function Navheader() {     
  return (
    <Fragment>
      <section id="hero2">
    
        <nav className="navigation">
      
      <Link to="/" className="logo"><span>See-</span>Docs</Link>
      
      <ul className="menu">
        <li><Link to="/doctors">Find A Doctor</Link></li>
        <li><Link to="specialists">Specialists ?</Link></li>
        <li><a href="http://store.thenavigo.com/" target="_blank" rel="noopener noreferrer">Store </a></li>
      </ul>
      
      <a href="#" className="nav-appointment-btn noHover">Appointment</a>
    </nav>

  </section>

        
    </Fragment>
    )
}

export default Navheader;